import React from "react";
import pageImage from "images/HomepageLogo.png";
import styled from "styled-components";
import { Col, Row } from "reactstrap";
import { Layout, PageContainer, SEO } from "components";

const LegalPage = ({ location }) => {
  const title = "ECL Terms of Service Agreement";
  const description = "Emerald Cloud Lab Terms of Service Agreement";
  return (
    <Layout clearNav={false} location={location}>
      <SEO
        image={pageImage}
        title={title}
        uri={location.pathname}
        description={description}
      />
      <LegalPageContainer>
        <Row>
          <StyledCol lg={12}>
            <Section>
              <Title>{title}</Title>
              <p>Updated: October 20, 2023</p>
            </Section>
            <Section>
              <p>
                This ECL Terms of Service Agreement (this “
                <strong>Agreement</strong>
                ”) is a binding contract between Emerald Cloud Lab, Inc. (“
                <strong>Emerald</strong>
                ,” “<strong>we</strong>
                ,” “<strong>us</strong>
                ,” or “<strong>our</strong>
                ”) and you or the entity you represent (“
                <strong>you</strong>
                ”). This Agreement takes effect when you click an “I Accept”
                button or checkbox presented with these terms or, if earlier,
                when you use any portion of the ECL (the “
                <strong>Effective Date</strong>
                ”). You represent that you are lawfully able to enter into
                contracts. If you are entering into this Agreement for an
                entity, you represent that you have legal authority to agree to
                this Agreement on that entity’s behalf and bind that entity.
                Please see Section 15 for definitions of certain capitalized
                terms used in this Agreement.
              </p>
            </Section>
            <Section>
              <SubHeading>1. Use of the ECL.</SubHeading>
              <p>
                1.1. Generally. You may access and use the ECL in accordance
                with this Agreement, all documentation, rules and regulations
                applicable to your use of the ECL, including the Third Party
                Service Terms and the Policies. We have and will retain sole
                control over the operation, provision, maintenance, and
                management of the ECL, and we may modify the ECL from time to
                time at our sole discretion, for any purpose deemed appropriate
                by us. You have and will retain sole control over the operation,
                maintenance, management of, and all access to and use of, any
                Content, Samples, and systems you maintain external to the ECL.
              </p>
              <p>
                1.2. Your Account. To access the Service, you must create an ECL
                account associated with a valid e-mail address. You are solely
                responsible for creating and maintaining the security of your
                ECL account log-in and for all activities that occur under your
                account with or without your knowledge or consent. You shall
                contact us immediately if you believe an unauthorized party may
                be using your account or if your account information is lost,
                stolen, or otherwise compromised.
              </p>
              <p>
                1.3. Third Party Content; Third Party Services. Third Party
                Content, such as papers and experimental protocols uploaded by
                other ECL Users, may be made available to you by other ECL
                Users. You acknowledge and agree that the ECL operates on or
                with or using APIs and/or other services operated or provided by
                third parties (“Third Party Services”).
              </p>
              <p>
                1.4. Research Only. The ECL is for scientific and technical
                research purposes only. The ECL shall not be used in clinical
                research or in the diagnosis and/or treatment of medical and
                physical conditions in humans, and shall not be used as a
                substitute for professional medical advice (“
                <b>Unauthorized Uses</b>
                ”).
              </p>
              <p>
                1.5. HIPAA Compliance. You represent and warrant that you will
                not use the ECL, directly or indirectly, to create, receive,
                maintain or transmit any protected health information (“
                <strong>PHI</strong>
                ”), as defined in the Health Insurance Portability and
                Accountability Act of 1996 and its implementing regulations (“
                <strong>HIPAA</strong>
                ”) and that your use of the ECL will not cause us to create,
                receive, maintain or transmit any PHI. You further represent and
                warranty that Your Content, Your Samples, and Your Experiments
                do not contain, use, describe, or disclose any PHI. You are
                solely responsible for ensuring that Your Content, Your Samples
                and Your Experiments comply with all applicable laws.
              </p>
            </Section>
            <Section>
              <SubHeading>
                2. Security, Content Privacy, Access, and Safety.
              </SubHeading>
              <p>
                2.1. ECL Security. Without limiting your obligations under
                Section 3 and subject to Section 9, we will implement reasonable
                and appropriate measures designed to help you secure Your
                Content against accidental or unlawful loss, access, or
                disclosure.
              </p>
              <p>
                2.2. Content Privacy. Unless otherwise specified by you or
                another ECL User with the necessary permissions, Your Content
                stored in the ECL shall remain private and accessible only to
                you, except as described in Section 2.5. You have the option of
                designating some or all of Your Content as publicly accessible.
                By designating any of Your Content as publicly accessible, you
                agree to make such Content available under the terms and
                conditions of the{" "}
                <ExternalLink
                  href="http://creativecommons.org/licenses/by/4.0/legalcode"
                  target="_blank"
                  rel="nofollow"
                >
                  Creative Commons Attribution 4.0 International Public License
                </ExternalLink>
                . You may change the accessibility of Your Content at any time,
                however, changing the accessibility of a portion of Content from
                public to private will not terminate the aforementioned Creative
                Commons license already granted to anyone who lawfully accessed
                that portion of Content while it was public. Any changes to the
                accessibility of Your Content only pertain to copies of Your
                Content stored within the ECL. We will make reasonable efforts
                to update the accessibility of Your Content within a reasonable
                period of time after your change request.
              </p>
              <p>
                2.3. Interaction. The ECL may be used only by a human being via
                the ECL User Interface and Emerald provided APIs. Without our
                express written authorization in advance, any attempt to use a
                robot, crawler, script, or organized group of humans to
                repeatedly access the ECL beyond its intended use, to execute
                systematic patterns of queries to learn about the internal
                workings of the ECL, to index the ECL, to train any machine
                learning models, or to do anything else that we deem as
                jeopardizing the integrity of our system is strictly forbidden,
                for which we may terminate or suspend your access to the ECL for
                specific Users or IP ranges.
              </p>
              <p>
                2.4. Right to Download. Subject to your full compliance with all
                the terms and conditions in this Agreement and the Third Party
                Service Terms, you shall have the right to download the raw
                experimental data associated with Your Experiments during the
                Term, and, where applicable, as supplemented in Section 6.
              </p>
              <p>
                2.5. Troubleshooting, System Integrity, Safety, and
                Improvements. We reserve the right to inspect Your Content, Your
                Samples, Your Experiments and any other instructions or
                materials submitted to or created in the ECL to ensure the
                safety, reliability, and performance of the ECL, to troubleshoot
                an Experiment to diagnose any potential errors, as well as to
                derive information to improve or augment any aspect of the ECL.
                For example, inspecting for Harmful Code in violation of Section
                3.3, inspecting for dangerous material types in violation of
                Section 3.2, deriving usage statistics to optimize instrument
                availability, and updating statistical models.
              </p>
              <p>
                2.6. Dangerous Materials. We reserve the right, at our sole
                discretion and upon notice to you, to immediately destroy or
                return any portion of Your Content, Your Samples, or Your
                Experiments, if we determine that such portion poses a safety or
                security risk to the ECL, or if destruction or return is
                required for the ECL to comply with any applicable law.&nbsp;
              </p>
            </Section>
            <Section>
              <SubHeading>3. Your Responsibilities.</SubHeading>
              <p>
                3.1. Your Experiments. Emerald does not provide any scientific
                advising services. You are solely responsible for the proper
                description and use of Your Experiments, including without
                limitation:
              </p>
              <Tabbed>
                (a) selecting a technically appropriate Model for each Sample
                sent to the ECL;
              </Tabbed>
              <Tabbed>
                (c) the technical operation of Your Experiments, including
                ensuring that calls you make to the ECL are compatible with
                then-current functions and APIs for the ECL, including the
                current version of SLL, and that Your Experiments do not pose a
                hazard to the ECL or Emerald personnel;
              </Tabbed>
              <Tabbed>
                (d) compliance of Your Experiments with the Policies and all
                applicable laws;
              </Tabbed>
              <Tabbed>(e) any claims relating to Your Experiments; and</Tabbed>
              <Tabbed>
                (f) properly handling and processing notices sent to you (or any
                of your affiliates) by any person claiming that Your Experiments
                violate such person’s rights.
              </Tabbed>
              <p>
                3.2. Your Samples. The ECL is a Biosafety Level 2 facility, as
                defined by the Center for Disease Control and Prevention. You
                are only permitted to submit and create Samples that are
                permitted to be handled in a Biosafety Level 2 or lower
                facility. You must truthfully and accurately disclose the
                identity and nature of Your Samples to Emerald in detail
                sufficient to ensure the ECL’s ability to process Your Samples
                and that Your Samples are not radioactive, highly volatile, or
                otherwise dangerous. All Samples you ship to the ECL must be
                shipped in compliance with all applicable regulations governing
                the shipment of hazardous materials. If Your Samples include a
                potentially hazardous substance that does not already have a
                corresponding Model in the ECL, Emerald will not accept such
                Samples before first certifying the Model you submit for the
                substance.
              </p>
              <p>
                3.3. Harmful or Illegal Experiments. You represent that Your
                Content, Your Samples, and Your Experiments are transmitted to
                the ECL in good faith and that Your Experiments will not damage
                any equipment in the ECL or otherwise harm the integrity of the
                ECL or pose a danger to anyone. You will not transmit or cause
                to be transmitted any Harmful Code to the ECL. You warrant that
                Your Experiments are not objectively dangerous to one skilled in
                the art and that none of Your Experiments will generate Samples
                that violate Section 3.2. Emerald reserves the right to seek
                compensation from you for any harm it suffers due to a violation
                of this Section, including but not limited to any expenses
                incurred as a result of the violation. You may not use the ECL
                to create or research illegal or illicit substances unless you
                have all applicable legal certifications and permissions.
              </p>
              <p>
                3.4. Regulatory Approval. You are responsible for securing any
                authorizations necessary for using restricted samples, reagents,
                or other materials on the ECL. Emerald is not responsible for
                any delays relating to securing any permits, certifications, or
                permissions necessary to execute an Experiment and reserves the
                right to refuse to execute any Experiment for any reason.
                Experiments conducted on the ECL are subject to Texas Health and
                Safety Code, Chapter 481; Texas Administrative Code, Title 37,
                Part I, Chapter 13, Subchapter B; applicable DEA regulations;
                and applicable local regulations. If you are legally entitled to
                use a restricted sample, reagent, or other material, you are
                responsible for providing us with legally-sufficient
                documentation for each applicable regulation for each restricted
                item.
              </p>
              <p>
                3.5. Security and Backup. You are responsible for properly
                configuring and using the ECL and maintaining appropriate
                security, protection, and backup of Your Content. ECL log-in
                credentials and private keys generated by the ECL are for your
                internal use only and you may not sell, transfer, or sublicense
                them to any other entity or person, except that you may disclose
                your private key to your agents and subcontractors performing
                work on your behalf.
              </p>
              <p>
                3.6. Subject User Violations. You shall ensure that all Subject
                Users comply with your obligations under this Agreement and that
                the terms of your agreement with each Subject User shall be at
                least as protective of the ECL and Emerald as those of this
                Agreement. If you become aware of any violation by a Subject
                User, you will immediately terminate such Subject User’s access
                to the ECL.
              </p>
              <p>
                3.7. Actions of Other Users. Any exchange of data or other
                interaction between you and other Users is solely between you
                and such other Users. Emerald is not responsible or liable for
                any actions taken by other Users, whether within a Team or not.
                For example, if a valid User within a Team elects to delete
                certain data or make it public, such User shall be solely
                responsible for those actions, not Emerald. Please construct
                your Teams with care. If you want to allow someone restricted
                access to certain data or experiments, Emerald suggests adding
                that User via a Sharing Team.&nbsp;
              </p>
              <p>
                3.8. Administrators. You shall designate at least one
                Administrator for each Team you construct. Only an Administrator
                may create a Team, add a User to a Team, and remove a User from
                a Team. The Responsible Entity may, upon written notice to
                Emerald, add or remove Administrators from a Team for which it
                is responsible.
              </p>
              <p>
                3.9. Effect of User Failure or Delay. Emerald is not responsible
                or liable for any delay or failure of performance caused, in
                whole or in part, by your delay in performing, or failure to
                perform, any of your obligations under this Agreement.
              </p>
            </Section>
            <Section>
              <SubHeading>4. Fees and Payment.</SubHeading>
              <p>
                4.1. Payment. The Responsible Entity of your Finance Team shall
                pay us all the applicable fees and charges for use of the ECL as
                described on the ECL User Interface. All amounts payable under
                this Agreement will be made using one of the payment methods we
                support, without setoff or counterclaim, and without any
                deduction or withholding. Emerald reserves the right to refuse
                to accept payment for any reason, upon which the transaction
                will be canceled and you will not be charged. Unless otherwise
                stated in writing, payment is due within 30 calendar days of
                receipt of invoice. Emerald may charge your Finance Team
                interest at the rate of 1.5% per month (or the highest rate
                permitted by law, if less) on all late payments.&nbsp;
              </p>
              <p>
                4.2. Pricing; Upfront payment is required for all pilot and
                subscription accounts. For upfront payments invoices are issued
                upon execution of the Agreement. All other line items, including
                Utilization Fees are invoiced monthly. Only Users who are
                members of your Finance Team are allowed to create and enqueue
                Experiments for your Team. Fees and charges for any new features
                or functionality of the ECL will be effective when updated in
                Constellation unless we expressly state otherwise in a notice.
              </p>
              <Tabbed>
                <em>Threads.</em> The Responsible Entity of your Finance Team
                may purchase Threads for you to run Experiments. Each Experiment
                requires at least one Thread to run, and running an Experiment
                may incur Utilization Fees. Any fees quoted through the ECL User
                Interface prior to the execution of an Experiment shall be
                estimates only.
              </Tabbed>
              <Tabbed>
                <em>Private Instances.</em> Where applicable, private instances
                on the ECL may incur other costs, for example controls and
                maintenance costs and space rental fees as specified in the
                addendum for private instances.
              </Tabbed>
              <p>
                4.3. Utilization Fees. We calculate and bill Utilization Fees on
                a monthly basis. Utilization Fees are calculated based on (1)
                any materials used (including applicable stocking fees),
                applicable shipping and handling fees, waste disposal, and, if
                applicable to your subscription type, instrument time, (2)
                storage of any Content and Samples during the month, and (3) if
                applicable, software license and data fees. Any updates or
                amendments to Utilization Fees will be effective when we post
                updated fees and charges on ECL Constellation unless we
                expressly state otherwise in a notice.
              </p>
              <p>
                4.4. Taxes. All fees and charges payable hereunder are exclusive
                of all taxes and duties, including VAT and applicable sales tax.
                The Team’s Responsible Entity is responsible for paying any
                applicable tax and duty relating to the Team’s use of the ECL,
                unless you have provided us with a valid exemption certificate.
                The Responsible Entity will provide us any information we
                reasonably request to determine whether we are obligated to
                collect sales tax or VAT from the Team. We will apply the tax
                exemption certificates to charges under the Team’s account
                occurring after the date we receive the tax exemption
                certificates. If any deduction or withholding is required by
                law, the Responsible Entity will notify us and will pay us any
                additional amounts necessary to ensure that the net amount that
                we receive, after any deduction and withholding, equals the
                amount we would have received if no deduction or withholding had
                been required. Additionally, the Responsible Entity will provide
                us with documentation showing that the withheld and deducted
                amounts have been paid to the relevant taxing authority.
              </p>
            </Section>
            <Section>
              <SubHeading>5. Temporary Suspension.</SubHeading>
              <p>
                5.1. Generally. Upon notice to you, we may suspend your or any
                Subject User’s right to access or use any portion or all of the
                ECL immediately, and/or cancel or refuse to run any Experiment
                for any reason including if we determine:
              </p>
              <Tabbed>
                (a) your or a Subject User’s access to or use of the ECL or your
                Experiment (i) poses a safety risk to the ECL, Emerald personnel
                or any third party, (ii) poses a security risk to the ECL or any
                third party, (iii) may adversely impact the ECL or the Samples
                or Content of any other ECL customer, (iii) may subject us, our
                affiliates, or any third party to liability, (iv) may constitute
                criminal or otherwise illegal activities, (v) may be fraudulent,
                or (vi) engages in malicious behavior;
              </Tabbed>
              <Tabbed>
                (b) you are, or any Subject User is, in breach of this
                Agreement, including if you are delinquent on your payment
                obligations for more than 30 days; or
              </Tabbed>
              <Tabbed>
                (c) you have ceased to operate in the ordinary course, made an
                assignment for the benefit of creditors or similar disposition
                of your assets, or become the subject of any bankruptcy,
                reorganization, liquidation, dissolution, or similar proceeding.
              </Tabbed>
              <p>
                5.2. Effect of Suspension. If we suspend your right to access or
                use any portion or all of the ECL:
              </p>
              <Tabbed>
                (a) the applicable Responsible Entity remains responsible for
                all fees and charges (i) you have incurred through the date of
                suspension, and (ii) for portions of the ECL to which you
                continue to have access, as well as applicable data and sample
                storage fees and charges, and fees and charges for in-process
                tasks completed after the date of suspension;
              </Tabbed>
              <Tabbed>
                (b) if the suspension applies to all of the ECL, you will be
                denied access to all aspects of the ECL vis-à-vis that Team
                except for purposes of rendering payment;&nbsp;
              </Tabbed>
              <Tabbed>
                (c) Your existing Experiments with status Accepted will not be
                run while the temporary suspension is in effect;
              </Tabbed>
              <Tabbed>
                (d) Your Experiments with status Running at the time of the
                suspension will, at Emerald’s sole discretion, run to
                completion, but in any event no further Experiments will be run
                for the duration of the suspension; and
              </Tabbed>
              <Tabbed>
                (e) we will not erase any of Your Content or destroy any of Your
                Samples as a result of your suspension, except as specified
                elsewhere in this Agreement.
              </Tabbed>
              <p>
                Our right to suspend your or any Subject User’s right to access
                or use the ECL is in addition to our other rights and remedies
                available under law or in equity, including our right to
                terminate this Agreement pursuant to Section 6.2.
              </p>
            </Section>
            <Section>
              <SubHeading>6. Term; Termination.</SubHeading>
              <p>
                6.1. Term. The term of this Agreement will commence on the
                Effective Date and will remain in effect until terminated by you
                or us in accordance with Section 6.2.
              </p>
              <p>6.2. Termination.</p>
              <Tabbed>
                (a) Termination for Convenience. You may terminate this
                Agreement for any reason by: (i) providing us notice and (ii)
                closing your account for all aspects of ECL for which we provide
                an account closing mechanism. We may terminate this Agreement
                for any reason by providing you 30 days advance notice.
              </Tabbed>
              <Tabbed>(b) Termination for Cause.</Tabbed>
              <DoubleTab>
                (i) By Either Party. Either party may terminate this Agreement
                for cause upon 30 days advance notice to the other party if
                there is any material default or breach of this Agreement by the
                other party, unless the defaulting party has cured the material
                default or breach within the 30 day notice period.
              </DoubleTab>
              <DoubleTab>
                (ii) By Us. We may also terminate this Agreement immediately
                upon notice to you (A) for cause, if any act or omission by you
                or any Subject User results in a suspension described in Section
                5.1, (B) if we believe providing any portion of the ECL could
                create a substantial economic or technical burden or material
                security risk for us, (C) in order to comply with the law or
                requests of governmental entities, (D) if we determine use of
                the ECL by you or any Subject Users or our provision of any
                portion of the ECL to you or any Subject Users has become
                impractical or unfeasible for any legal or regulatory reason, or
                (E) if our relationship with a third party partner who provides
                software or other technology we use to provide the ECL expires,
                terminates or requires us to change the way we provide the
                software or other technology as part of the ECL.
              </DoubleTab>
              <p>6.3. Effect of Termination.</p>
              <Tabbed>
                (a) Generally. Upon any termination of this Agreement:
              </Tabbed>
              <DoubleTab>
                (i) all your rights under this Agreement immediately terminate;
              </DoubleTab>
              <DoubleTab>
                (ii) the applicable Responsible Entity remains responsible for
                all fees and charges you have incurred through the date of
                termination, including fees and charges for in-process tasks
                completed after the date of termination; any fees and charges
                incurred after termination for the storage, shipment, and
                disposal of Your Content and Your Samples; and any monthly fees
                through the end of the month in which termination occurred,{" "}
                <em>i.e.</em>, monthly fees will not be prorated for partial
                months;
              </DoubleTab>
              <DoubleTab>
                (iii) you will immediately return or, if instructed by us,
                destroy all ECL Content in your possession;&nbsp;
              </DoubleTab>
              <DoubleTab>
                (iv) all of Your Experiments except those with status Running
                will be canceled; and
              </DoubleTab>
              <DoubleTab>
                (v) Sections 3.1, 3.8, 4, 6.3, 7 (except the license granted to
                you in Section 7.3), 8, 9, 10, 12 and 14 will continue to apply
                in accordance with their terms.
              </DoubleTab>
              <Tabbed>
                (b) Post-Termination Assistance. Unless we terminate this
                Agreement pursuant to Section 6.2(b), during the 30 days
                following termination:
              </Tabbed>
              <DoubleTab>
                (i) we will not erase any of Your Content or destroy any of Your
                Samples as a result of the termination, except as provided in
                Section 2.6;
              </DoubleTab>
              <DoubleTab>
                (ii) you may retrieve Your Content and Your Samples from the ECL
                only if the applicable Responsible Entity has paid any charges
                for any post-termination use of the ECL and all other amounts
                due, including any fees and charges associated with the
                retrieval; and
              </DoubleTab>
              <DoubleTab>
                (iii) we will provide you with the same post-termination data
                retrieval assistance that we generally make available to all
                customers. Any additional post-termination assistance from us is
                subject to mutual agreement by you and us.&nbsp;
              </DoubleTab>
              <Tabbed>
                (c) Abandoned Content and Samples. By the 30th calendar day
                following termination, if any of Your Content or Your Samples
                remain in the ECL you must elect, within 60 days, whether
                Emerald is to return or destroy all of Your Content and Your
                Samples and pay any fees and charges related to your election.
                If the applicable Responsible Entity does not pay the requisite
                fees and charges relating to your election, or do not make an
                election, prior to the expiration of such 60-day period, Your
                Content and Your Samples will be considered abandoned. Emerald
                reserves the right to destroy abandoned Content and Samples, and
                to bill you or the applicable Responsible Entity for any
                associated costs. Emerald is not liable for the destruction of
                abandoned Content and Samples.
              </Tabbed>
              <p>
                6.4. Permanent Bans. If Emerald terminates this Agreement for
                cause under Section 6.2(b)(ii)(A)–(D), then Emerald may, at its
                sole discretion, permanently ban you from accessing the ECL. If
                you attempt to circumvent the ban, <em>e.g.</em>, access the ECL
                using another User’s account or create a new account under a
                different identity, any positive balance associated with that
                account will be seized by Emerald and considered forfeit. You
                shall be solely responsible for and indemnify and hold Emerald
                harmless against any damages, losses, liabilities, and expenses
                that may be claimed by such other User.&nbsp;
              </p>
            </Section>
            <Section>
              <SubHeading>7. Proprietary Rights.</SubHeading>
              <p>
                7.1. Your Content and Samples. As between you and us, you own
                all right, title, and interest in and to Your Content and Your
                Samples. Except as provided in this Section 7, we obtain no
                rights under this Agreement from you to Your Content, including
                any related intellectual property rights. You hereby grant us a
                limited, royalty-free, fully-paid up, non-exclusive, worldwide,
                transferable, and sublicensable license to use Your Content and
                Samples for the purpose of (i) effectuating Section 2.5, (ii)
                providing the ECL to you, Subject Users, and others with
                permission to access Your Content, and (iii) otherwise
                performing our obligations and exercising our rights hereunder.
                We may disclose Your Content to comply with any request of a
                governmental or regulatory body (including subpoenas or court
                orders).&nbsp;
              </p>
              <p>
                7.2. Warranties. You represent and warrant to us that: (a) you
                or your licensors own all right, title, and interest in and to
                Your Content and Your Samples; (b) you have all rights,
                including regulatory permissions, in Your Content and Your
                Samples necessary to grant the rights contemplated by this
                Agreement; (c) none of Your Content, Your Samples or Subject
                Users’ use of Your Content or Your Samples on the ECL violates
                your obligations under Sections 2 and 3 of this Agreement; and
                (d) your actions on the ECL are in compliance with all laws and
                regulations of all applicable jurisdictions. We reserve the
                right to request legally-sufficient documentation confirming
                your rights under this Section.
              </p>
              <p>
                7.3. ECL License. We or our affiliates or licensors own and
                reserve all right, title, and interest in and to the ECL and the
                ECL Content. Subject to your full compliance with the terms and
                conditions hereunder, we grant you a limited, revocable,
                non-exclusive, non-sublicensable, non-transferable license,
                during the Term, to: (i) access and use the ECL solely in
                accordance with this Agreement; and (ii) copy and use ECL
                Content solely in support of your permitted use of the ECL.
                Except as provided in this Section 7.3, you obtain no rights
                under this Agreement from us or our licensors to the ECL or its
                supporting systems or the ECL Content, including any related
                intellectual property rights. Some ECL Content may be provided
                to you under a separate license, such as the Wolfram Mathematica
                License Agreement or Apache Software License. In the event of a
                conflict between this Agreement and any separate license, the
                separate license will prevail with respect to that ECL Content.
              </p>
              <p>
                7.4. License Restrictions. Neither you nor any Subject User may
                (nor shall you permit others to) use the ECL in any manner or
                for any purpose other than as expressly permitted by this
                Agreement. Neither you nor any Subject User may, or may attempt
                to, nor shall you permit others to, (a) modify, alter, tamper
                with, repair, or otherwise create derivative works of any
                software included in the ECL (except to the extent software
                included in the ECL are provided to you under a separate license
                that expressly permits the creation of derivative works), (b)
                reverse engineer, disassemble, or decompile any portion of the
                ECL or apply any other process or procedure to derive the source
                code, underlying structure, ideas or algorithms, knowledge
                representations, Symbolic Lab Language implementations, or
                database specifications of the ECL (including any software
                included therein and any data or documentation related thereto),
                (c) access or use the ECL in a way intended to avoid incurring
                fees or exceeding usage limits or quotas or for any purpose
                other than its own internal use for its own internal benefit,
                (d) resell or sublicense the ECL, or (e) use the ECL or software
                other than in accordance with this Agreement and in compliance
                with all applicable laws, regulations and rights (including but
                not limited to those related to privacy, intellectual property,
                obscenity, or defamation). All licenses granted to you in this
                Agreement are conditional on your continued compliance this
                Agreement, and will immediately and automatically terminate if
                you do not comply with any term or condition of this Agreement.
                During and after the Term, you will not assert, nor will you
                authorize, assist, or encourage any third party to assert,
                against us or any of our affiliates, customers, vendors,
                business partners, or licensors, any patent infringement or
                other intellectual property infringement claim regarding any
                aspect of the ECL you have used.&nbsp;
              </p>
              <p>
                7.5. Suggestions. If you provide any Suggestions to us or our
                affiliates, we will own all right, title, and interest in and to
                the Suggestions and Suggestions shall be deemed as our
                confidential information. Suggestions shall not be deemed as
                your confidential information, even if you have designated the
                Suggestions as confidential. You hereby irrevocably assign to us
                all right, title, and interest in and to the Suggestions and
                agree to provide us any assistance we may require to document,
                perfect, and maintain our rights in the Suggestions.
              </p>
            </Section>
            <Section>
              <SubHeading>8. Indemnification.</SubHeading>
              <p>
                8.1. General. You will defend, indemnify, and hold harmless us,
                our affiliates and licensors and business partners, and each of
                their respective employees, officers, directors, and
                representatives from and against any claims, damages, losses,
                liabilities, costs, and expenses (including reasonable
                attorneys’ fees) arising out of or relating to any third party
                claim concerning: (a) your or any Subject Users’ use of the ECL
                (including any activities under your ECL account); (b) breach of
                this Agreement or violation of applicable law by you or any
                Subject User; (c) Your Content, or the combination of Your
                Content with other applications, Content or processes, including
                any claim involving alleged infringement or misappropriation of
                third party rights by Your Content or by the use, development,
                design, production, Processing, advertising or marketing of Your
                Content; (d) Your Samples or the use of Your Samples, including,
                without limitation, in combination with other Samples; (e) the
                destruction of any abandoned Content or Samples as described in
                Section 6; or (f) a dispute between you and any Subject User or
                fellow Team User. If we or our affiliates are obligated to
                respond to a third party subpoena or other compulsory legal
                order or process described above, you will also reimburse us for
                reasonable attorneys’ fees, as well as our employees’ and
                contractors’ time and materials spent responding to the third
                party subpoena or other compulsory legal order or process at our
                then-current hourly rates.
              </p>
              <p>
                8.2. Process. We will promptly notify you of any claim subject
                to Section 8.1, but our failure to promptly notify you will only
                affect your obligations under Section 8.1 to the extent that our
                failure prejudices your ability to defend the claim. You may:
                (a) use counsel of your own choosing (subject to our written
                consent) to defend against any claim; and (b) settle the claim
                as you deem appropriate, provided that you obtain our prior
                written consent before entering into any settlement. We may also
                assume control of the defense and settlement of the claim at any
                time.
              </p>
              <p>
                8.3 Patent Claims. Emerald agrees to defend at its expense any
                suits brought by a third party against you to the extent based
                upon a claim that use of the ECL itself as authorized under this
                Agreement infringes any U.S. registered patent, and to pay costs
                and damages finally awarded in any such suit against you;
                provided that you will promptly notify Emerald in writing of any
                claim or suit, give Emerald the opportunity to assume the sole
                control of such claim or suit to defend or settle, and provide
                reasonable assistance requested by Emerald; Emerald will not be
                responsible for any settlement it does not approve. We shall
                have no such obligation with respect to any suit arising, in
                whole or in part, from (i) your negligence or willful
                misconduct, (ii) breach by you of any of your obligations under
                this Agreement, (iii) Your Content or Your Samples, (iv) any
                action or inaction of Emerald taken at your direction; (v)
                access to or use of the ECL in combination with any hardware,
                system, software, network, products, instruments, equipment or
                other materials manufactured by third parties; (vi)
                modifications of the ECL Content by you or on your behalf; or
                (vii) any malfunction or defects in any products, materials,
                instruments or equipment manufactured by third parties that you
                use through the ECL, including, without limitation, lab
                equipment and computing equipment..
              </p>
            </Section>
            <Section>
              <SubHeading>9. Disclaimers.</SubHeading>
              <p>
                THE ECL AND ANYTHING PROVIDED IN CONNECTION WITH THIS AGREEMENT
                IS PROVIDED “AS IS.” WE AND OUR AFFILIATES AND LICENSORS MAKE NO
                REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS,
                IMPLIED, STATUTORY, OR OTHERWISE REGARDING THE ECL, ANY CONTENT,
                ANYTHING PROVIDED IN CONNECTION WITH THIS AGREEMENT, OR ANY
                ACTION OF THIRD PARTIES, INCLUDING ANY WARRANTY THAT THE ECL OR
                THIRD PARTY CONTENT WILL BE UNINTERRUPTED, ERROR FREE OR FREE OF
                HARMFUL COMPONENTS, YOUR EXPERIMENTS WILL BE COMPLETED WITHIN A
                SPECIFIC TIME FRAME OR YIELD A PARTICULAR RESULT, OR THAT ANY
                CONTENT, SAMPLES, OR EXPERIMENTS, WILL BE SECURE OR NOT
                OTHERWISE LOST OR DAMAGED. FURTHER, WE AND OUR AFFILIATES AND
                OUR LICENSORS ARE NOT RESPONSIBLE FOR ANY DELAYS BY THIRD
                PARTIES, INCLUDING DELAYS IN THE DELIVERY OF REAGENTS,
                EQUIPMENT, AND EXPERIMENTAL MATERIALS, AS WELL AS DELAYS IN
                GRANTING OF ANY REGULATORY APPROVALS. WE AND OUR AFFILIATES AND
                OUR LICENSORS DO NOT REPRESENT OR WARRANT THAT WE WILL PROVIDE
                SUPPORT FOR VIEWING YOUR CONTENT OUTSIDE OF THE ECL USER
                INTERFACE. WE AND OUR AFFILIATES AND OUR LICENSORS DO NOT
                REPRESENT OR WARRANT THAT WE WILL PROVIDE SUPPORT FOR VIEWING
                YOUR CONTENT BEYOND THE TERM OF THIS AGREEMENT. WE AND OUR
                AFFILIATES AND OUR LICENSORS ARE NOT RESPONSIBLE FOR ANY DELAYS
                IN CHANGING THE PRIVACY SETTINGS OF YOUR CONTENT, OR ANY
                CONSEQUENCES OF SUCH DELAYS. EXCEPT TO THE EXTENT PROHIBITED BY
                LAW, WE AND OUR AFFILIATES AND LICENSORS DISCLAIM ALL
                WARRANTIES, INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
                SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE,
                NON-INFRINGEMENT, OR QUIET ENJOYMENT, AND ANY WARRANTIES ARISING
                OUT OF ANY COURSE OF DEALING OR USAGE OF TRADE.
              </p>
              <p>
                We do not make any representations or warranties with respect to
                Third Party Content and/or Third Party Services, and we are not
                responsible for your use of the Third Party Content, the
                operation of any Third Party Services, nor the availability or
                operation of the ECL to the extent such availability and
                operation is dependent upon Third Party Services.
              </p>
              <p>
                The ECL is not a CLIA registered laboratory and we make no
                representation or warranty as to compliance with 21 C.F.R. § 58.
                You acknowledge that the results of the testing requested are
                only for the authorized uses set forth herein and further
                acknowledge that WE DISCLAIM ANY AND ALL LIABILITY AND/OR
                RESPONSIBILITY FOR USE OF THE ECL OR RESULTS FOR UNAUTHORIZED
                USES.
              </p>
            </Section>
            <Section>
              <SubHeading>10. Limitations of Liability.</SubHeading>
              <p>
                WE AND OUR AFFILIATES OR LICENSORS WILL NOT BE LIABLE TO YOU FOR
                ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR
                EXEMPLARY DAMAGES (INCLUDING DAMAGES FOR LOSS OF PROFITS,
                GOODWILL, USE, OR DATA), EVEN IF A PARTY HAS BEEN ADVISED OF THE
                POSSIBILITY OF SUCH DAMAGES. FURTHER, NEITHER WE NOR ANY OF OUR
                AFFILIATES OR LICENSORS WILL BE RESPONSIBLE FOR ANY
                COMPENSATION, REIMBURSEMENT, OR DAMAGES ARISING IN CONNECTION
                WITH: (A) YOUR USE OF OR INABILITY TO USE THE ECL; (B) THE COST
                OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; (C) ANY
                INVESTMENTS, EXPENDITURES, OR COMMITMENTS BY YOU IN CONNECTION
                WITH THIS AGREEMENT; OR (D) ANY UNAUTHORIZED ACCESS TO,
                ALTERATION OF, OR THE DELETION, DESTRUCTION, DAMAGE, LOSS OR
                FAILURE TO STORE ANY OF YOUR EXPERIMENTS, YOUR CONTENT, YOUR
                SAMPLES, OR OTHER DATA. TO THE MAXIMUM EXTENT PERMITTED BY
                APPLICABLE LAWS, OUR AND OUR AFFILIATES’ AND LICENSORS’
                AGGREGATE LIABILITY UNDER THIS AGREEMENT WILL BE LIMITED TO THE
                AMOUNT YOU ACTUALLY PAID US UNDER THIS AGREEMENT FOR THE
                SPECIFIC EXPERIMENT THAT GAVE RISE TO THE CLAIM DURING THE 12
                MONTHS PRECEDING THE CLAIM.
              </p>
            </Section>
            <Section>
              <SubHeading>11. Modifications to the Agreement.</SubHeading>
              <p>
                We may modify this Agreement (including any Policies) at any
                time by posting a revised version on the ECL User Interface or
                by otherwise notifying you in accordance with Section 12.6. The
                modified terms will become effective upon posting or, if we
                notify you by email, as stated in the email message. By
                continuing to use the ECL after the effective date of any
                modifications to this Agreement, you agree to be bound by the
                modified terms. It is your responsibility to check the ECL User
                Interface regularly for modifications to this Agreement.
              </p>
            </Section>
            <Section>
              <SubHeading>12. Miscellaneous.</SubHeading>
              <p>
                12.1. Confidentiality and Publicity. You may use ECL
                Confidential Information only in connection with your use of the
                ECL as permitted under this Agreement. You will not disclose ECL
                Confidential Information during the Term or at any time during
                the 5 year period following the end of the Term. You will take
                all reasonable measures to avoid disclosure, dissemination, or
                unauthorized use of ECL Confidential Information, including, at
                a minimum, those measures you take to protect your own
                confidential information of a similar nature. You will not issue
                any press release or make any other public communication with
                respect to this Agreement or your use of the ECL, unless
                authorized in writing by Emerald. You will not misrepresent or
                embellish the relationship between us and you (including by
                expressing or implying that we support, sponsor, endorse, or
                contribute to you or your business endeavors), or express or
                imply any relationship or affiliation between us and you or any
                other person or entity except as expressly permitted by this
                Agreement.
              </p>
              <p>
                12.2. Force Majeure. We and our affiliates will not be liable
                for any delay or failure to perform any obligation under this
                Agreement where the delay or failure results from any cause
                beyond our reasonable control, including acts of God; labor
                disputes or other industrial disturbances; systemic electrical,
                telecommunications, or other utility failures; national or
                regional shortage of adequate power, telecommunications,
                transportation, laboratory supplies, or laboratory equipment;
                earthquake, storms or other elements of nature; blockages;
                embargoes; riots or other civil unrest; acts or orders of
                government; or acts of terrorism, invasion, or war.
              </p>
              <p>
                12.3. Independent Contractors; Non-Exclusive Rights. We and you
                are independent contractors, and neither party, nor any of their
                respective affiliates, is an agent of the other for any purpose
                or has the authority to bind the other. Both parties reserve the
                right (a) to develop or have developed for it products,
                services, concepts, systems, or techniques that are similar to
                or compete with the products, services, concepts, systems, or
                techniques developed or contemplated by the other party and (b)
                to assist third parties who may offer products or services which
                compete with the other party’s products or services; provided
                that in doing so such party shall not breach this
                Agreement.&nbsp;
              </p>
              <p>
                12.4. No Third Party Beneficiaries. This Agreement does not
                create any third party beneficiary rights in any individual or
                entity that is not a party to this Agreement.
              </p>
              <p>
                12.5. Import and Export Compliance. Each party will comply with
                all applicable import, re-import, export, and re-export control
                laws and regulations, including the Export Administration
                Regulations, the International Traffic in Arms Regulations, and
                country-specific economic sanctions programs implemented by the
                Office of Foreign Assets Control. For clarity, you are solely
                responsible for compliance related to the manner in which you
                choose to use the ECL, including your transfer and processing of
                Your Content and Your Samples, the provision of Your Content and
                Your Samples to Subject Users, and the geographic region in
                which any of the foregoing occur. For reference, the physical
                experimentation portion of the ECL is based in the state of
                California, United States of America.
              </p>
              <p>12.6. Notice.</p>
              <Tabbed>
                (a) To You. We may provide any notice to you under this
                Agreement by: (i) posting a notice on the ECL User Interface, or
                (ii) sending a message to the email address then associated with
                your account. Notices we provide by posting on the ECL User
                Interface will be effective upon posting and notices we provide
                by email will be effective when we send the email. It is your
                responsibility to keep your email address current. You will be
                deemed to have received any email sent to the email address then
                associated with your account when we send the email, whether or
                not you actually receive the email.
              </Tabbed>
              <Tabbed>
                (b) To Us. Unless otherwise specified in this Agreement, to give
                us notice under this Agreement, you must contact ECL as follows:
                (i) through the relevant aspect of the ECL User Interface or
                (ii) email sent to notice@emeraldcloudlab.com. We may update the
                email address for notices to us by posting a notice on the ECL
                User Interface. Notices provided to us by email will be
                effective one business day after the email they are sent.
              </Tabbed>
              <Tabbed>
                (c) Language. All communications and notices to be made or given
                pursuant to this Agreement must be in the English language.
              </Tabbed>
              <p>
                12.7. Assignment. You will not assign this Agreement, or
                delegate or sublicense any of your rights under this Agreement,
                without our prior written consent. Any assignment or transfer in
                violation of this Section 12.7 will be void. Emerald may
                transfer and assign any of its rights and obligations under this
                Agreement without your consent. Subject to the foregoing, this
                Agreement will be binding upon, and inure to the benefit of the
                parties and their respective successors and assigns.
              </p>
              <p>
                12.8. No Waivers. The failure by us to enforce any provision of
                this Agreement will not constitute a present or future waiver of
                such provision nor limit our right to enforce such provision at
                a later time. All waivers by us must be in writing to be
                effective.
              </p>
              <p>
                12.9. Severability. If any portion of this Agreement is held to
                be invalid or unenforceable, the remaining portions of this
                Agreement will remain in full force and effect. Any invalid or
                unenforceable portions will be interpreted in a manner that most
                closely reflects the effect and intent of the original portion.
                If such construction is not possible, the invalid or
                unenforceable portion will be severed from this Agreement but
                the rest of the Agreement will remain in full force and effect.
              </p>
              <p>
                12.10. Governing Law; Venue; Arbitration. The laws of the State
                of Delaware, without reference to conflict of law rules, govern
                this Agreement and any dispute of any sort that might arise
                between you and us.
              </p>
              <p>
                You and we agree that any and all claims arising from or
                relating to this Agreement or any service provided by Emerald to
                you shall be subject to binding arbitration under the Federal
                Arbitration Act (“
                <strong>FAA</strong>
                ”). This includes claims based on contract, tort, equity, other
                common law, statute, or otherwise, as well as claims regarding
                the scope and enforceability of this provision.
              </p>
              <p>
                A single arbitrator shall decide all claims and shall render a
                final, written decision. Both parties agree to choose Judicial
                Arbitration and Mediation Service (“
                <strong>JAMS</strong>
                ”) to administer the arbitration. Consistent with the FAA, the
                appropriate JAMS rules shall apply, as determined by the
                arbitrator.
              </p>
              <p>
                The arbitration shall take place in the San Francisco Bay Area,
                California.
              </p>
              <p>
                Each party to the arbitration shall pay his, her, or its own
                costs of arbitration.&nbsp;
              </p>
              <p>
                The parties waive any right to bring representative claims on
                behalf of a class of individuals, on behalf of the public, as a
                private attorney general, or otherwise (the “
                <strong>class action waiver</strong>
                ”). Except for this class action waiver, this clause may be
                severed or modified if necessary to render it enforceable under
                the FAA.
              </p>
              <p>
                12.11. Entire Agreement; English Language. This Agreement
                includes the Policies and is the entire agreement between you
                and us regarding the subject matter of this Agreement. This
                Agreement supersedes all prior or contemporaneous
                representations, understandings, agreements, or communications
                between you and us, whether written or verbal, regarding the
                subject matter of this Agreement. Notwithstanding any other
                agreement between you and us, the security and data privacy
                provisions in Section 2 of this Agreement contain our and our
                affiliates’ entire obligation regarding the security, privacy,
                and confidentiality of Your Content. We will not be bound by,
                and specifically object to, any term, condition, or other
                provision which is different from or in addition to the
                provisions of this Agreement (whether or not it would materially
                alter this Agreement) and which is submitted by you in any
                order, receipt, acceptance, confirmation, correspondence, or
                other document, unless Emerald explicitly agrees to such term,
                condition, or provision in writing. If the terms of this
                Agreement are inconsistent with the terms contained in any
                Policy, the terms contained in this Agreement will control,
                except that the Third Party Service Terms will control over this
                document. If we provide a translation of the English language
                version of this Agreement, the English language version of the
                Agreement will control if there is any conflict.
              </p>
            </Section>
            <Section>
              <SubHeading>13. Refunds.</SubHeading>
              <p>
                13.1. Generally. In general there are no refunds except as
                specified in this Section.
              </p>
              <p>
                13.2. Refunds for Specific Experiments. Any refund requests must
                be tied to a specific Experiment and made through the ECL User
                Interface as part of the troubleshooting process for a specific
                Experiment. Troubleshooting requests must be made within 30
                calendar days of the completion of the Experiment. If, upon
                completion of the troubleshooting process, the issue is
                unresolved to your satisfaction, Emerald may, at its sole
                discretion, offer to issue a refund for all or part of the
                eligible associated fees. Refunds will be issued within 90
                calendar days after the request has been approved by Emerald.
              </p>
              <p>
                13.3. Nonrefundable Items. Utilization Fees detailed in Section
                4.3 are nonrefundable. Fees assessed for Sample intake kits and
                the shipping and handling of Samples are nonrefundable.
              </p>
              <p>
                13.4. Refunds for Cancellation of Contract. In the event of
                termination for convenience pursuant to Section 6.2(a), if the
                termination occurs (all days herein are calendar days):
                <Tabbed>(a) For subscription payments:</Tabbed>
                <DoubleTab>
                  (i) Subscription fees up to the date of termination are
                  non-refundable. Early termination fees, if applicable, will be
                  specified in the Purchase Order.
                </DoubleTab>
                <Tabbed>(b) For up front payments:</Tabbed>
                <DoubleTab>
                  (i) within the first 30 days of the Term then 100% of up front
                  payment, less Utilization Fees will be refunded;
                </DoubleTab>
                <DoubleTab>
                  (ii) between days 31 to 90 the prorated portion of the up
                  front payment, less Utilization Fees, up to date of
                  termination will be refunded; and
                </DoubleTab>
                <DoubleTab>
                  (iii) after 90th day of the Term the up front payment is not
                  eligible for refunds.
                </DoubleTab>
              </p>
              <p>
                13.5. Foreign Currency. All payment and refund hereunder is
                denominated and shall be made in US Dollars (“
                <strong>USD</strong>
                ”). If you request the payment to be made in a foreign currency,
                then the exchange rate utilized by Emerald at the time of the
                transaction shall govern in calculating the value of the
                original transaction in USD. If you request that the refund be
                made in any currency other than USD, then the refund shall be
                converted to that currency at the current exchange rate utilized
                by Emerald at the time the refund is issued. Emerald is not
                responsible for any change in the value of the refund due to
                currency exchanges.
              </p>
              <p>
                13.6. Withholding. Emerald reserves the right to withhold a
                portion of the requested refund sufficient to defray any
                maintenance fees, sample destruction, or shipment costs
                associated with the refund request.
              </p>
            </Section>
            <Section>
              <SubHeading>14. Definitions.</SubHeading>
              <p>
                “<strong>Accepted</strong>” means that your experiment is
                waiting for available resources to begin (such as
                instrumentation, materials, etc.), as indicated through the ECL
                User Interface.
              </p>
              <p>
                “<strong>Administrator</strong>” means a User with the
                additional ability to add and remove Users from a Team.
              </p>
              <p>
                “<strong>API</strong>” means an application program interface.
              </p>
              <p>
                “<strong>Command Center</strong>” (“
                <strong>CC</strong>
                ”) means the web-enabled software interface to the ECL used to
                direct experimentation, visualize data, analyze data, search and
                browse through data, share data, as well as conduct numerical
                simulations.
              </p>
              <p>
                “<strong>Constellation</strong>” means the ECL cloud-based
                repository utilized by ECL software to coordinate data.
              </p>
              <p>
                “<strong>Content</strong>” means software (including machine
                images), APIs, code, data, Models, knowledge representations,
                text, audio, video, images, or any other work of authorship.
              </p>
              <p>
                “
                <strong>
                  Creative Commons Attribution 4.0 International Public License
                </strong>
                ” references the license viewable at{" "}
                <ExternalLink
                  href="http://creativecommons.org/licenses/by/4.0/legalcode"
                  rel="nofollow"
                >
                  http://creativecommons.org/licenses/by/4.0/legalcode
                </ExternalLink>
                .
              </p>
              <p>
                “<strong>Documentation</strong>” means the developer guides,
                getting started guides, user guides, quick reference guides,
                manuals, instructions, function help files, or other documents
                or materials that Emerald or its affiliates provide or make
                available to you in any form or medium and which describe the
                functionality, components, features or requirements of the ECL,
                or supporting functionality, including any aspect of the
                installation, configuration, integration, operation, use,
                support, or maintenance thereof.
              </p>
              <p>
                “<strong>ECL</strong>” means the experimental, analytical, and
                computational services of the Emerald Cloud Lab, including
                associated APIs, made available by us or our affiliates through
                the ECL User Interface, including any supporting services and
                functionality described in the Third Party Service Terms,
                further including all new versions, updates, revisions,
                improvements, and modifications of the foregoing that we provide
                remote access to and use of as part of the Emerald Cloud Lab.
                ECL does not include Third Party Content.
              </p>
              <p>
                “<strong>ECL Confidential Information</strong>” means all
                nonpublic information disclosed by us, our affiliates, business
                partners, or our or their respective employees, contractors, or
                agents that is designated as confidential or that, given the
                nature of the information or circumstances surrounding its
                disclosure, reasonably should be understood to be confidential.
                ECL Confidential Information includes: (a) nonpublic information
                relating to our or our affiliates or business partners’
                technology, customers, business plans, promotional and marketing
                activities, finances, and other business affairs; (b) third
                party information that we are obligated to keep confidential;
                and (c) the nature, content, and existence of any discussions or
                negotiations between you and us or our affiliates. ECL
                Confidential Information does not include any information that:
                (i) is or becomes publicly available without breach of this
                Agreement; (ii) can be shown by documentation to have been known
                to you without confidentiality obligations at the time of your
                receipt from us; (iii) is received by you without
                confidentiality obligations from a third party who did not
                acquire or disclose the same by a wrongful or tortious act; or
                (iv) can be shown by documentation to have been independently
                developed by you without reference to the ECL Confidential
                Information.
              </p>
              <p>
                “<strong>ECL&nbsp;Content</strong>” means Content we or any of
                our affiliates make available in connection with the ECL or on
                the ECL User Interface to allow access to and use of the ECL,
                including Web Services Description Languages; APIs,
                Documentation; sample code; software libraries; Wolfram Language
                functions; Symbolic Lab Language; command line tools; Models;
                and other related technologies.
              </p>
              <p>
                “<strong>ECL&nbsp;Marks</strong>” means any trademarks, service
                marks, service or trade names, logos, and other designations of
                Emerald and its affiliates that we may make available to you in
                connection with this Agreement.
              </p>
              <p>
                “<strong>ECL User Interface</strong>” means the human–computer
                interface provided by us to enable you to access the ECL. This
                includes the Command Center, any website, command-line
                interface, Mathematica notebook, browser, or software
                development environment.
              </p>
              <p>
                “<strong>Effective Date</strong>” has the meaning set forth in
                the preamble.
              </p>
              <p>
                “<strong>Experiment</strong>” means any SLL function that starts
                with Experiment and produces a protocol object, as well as the
                actual physical actions performed in a laboratory pursuant to
                that protocol object.
              </p>
              <p>
                “<strong>Finance Team</strong>” means a type of Team where ECL
                Users share access to a billing account to pay for charges
                accrued by that Team. The Team’s Responsible Entity accepts all
                legal and financial liability for a Finance Team and the actions
                of that Team’s Users.
              </p>
              <p>
                “<strong>Harmful Code</strong>” means any software, hardware, or
                other technology, device, or means, including any virus, worm,
                malware, other malicious computer code, or Experiment, the
                purpose or effect of which is to (a) permit unauthorized access
                to, or to destroy, disrupt, disable, distort, or otherwise harm
                or impede in any manner any (i) computer, software, firmware,
                hardware, laboratory equipment, system, or network or (ii) any
                application or function of any of the foregoing or the security,
                integrity, confidentiality, or use of any Content or Experiment
                thereby, or (b) prevent any other Users from accessing or using
                the ECL as intended by this Agreement.
              </p>
              <p>
                “<strong>Model</strong>” means a database object in the ECL
                which contains information regarding the general concepts
                relating to an Object, such as the physical properties that a
                class of chemical may possess or the type of instrumentation.
              </p>
              <p>
                “<strong>Object</strong>” means a database object in the ECL
                which contains information about specific physical
                instantiations, such as a chemical sample, an instrument, or a
                piece of recorded data.&nbsp;
              </p>
              <p>
                “<strong>Policies</strong>” means the Third Party Service Terms,
                all restrictions described in the ECL Content and on the ECL
                User Interface, and any other policy or terms referenced in or
                incorporated into this Agreement. The term Policies does not
                include white-papers or other marketing materials referenced on
                the ECL User Interface or any website owned by Emerald.
              </p>
              <p>
                “<strong>Process</strong>” means to take any action or perform
                any operation or set of operations that the ECL is capable of
                taking or performing on any data, information or other content,
                including to collect, receive, input, upload, download, record,
                reproduce, store, organize, compile, combine, log, catalog,
                cross-reference, manage, maintain, copy, adapt, alter,
                translate, or make other derivative works or improvements,
                process, retrieve, output, consult, use, perform, display,
                disseminate, transmit, submit, post, transfer, disclose, or
                otherwise provide or make available, or block, erase or destroy.
                “<strong>Processing</strong>” and “<strong>Processed</strong>”
                have correlative meanings.
              </p>
              <p>
                “<strong>Responsible Entity</strong>” means the individual,
                corporation, limited liability company, trust, joint venture,
                association, company, limited or general partnership,
                unincorporated organization, governmental authority, or other
                entity that accepts all legal and financial responsibility for
                ECL Users on a Finance Team.
              </p>
              <p>
                “<strong>Sample</strong>” means any physical substance, item, or
                container that is or will be associated with an object in
                Constellation. Non-limiting examples include chemical reagents,
                cell cultures, oligopeptides, chromatography columns, and
                96-well plates.
              </p>
              <p>
                “<strong>Sharing Team</strong>” means a type of Team whose
                members have access permission to the shared data within the
                Team.
              </p>
              <p>
                “<strong>Symbolic Lab Language</strong>” (“
                <strong>SLL</strong>
                ”) means the underlying programming language (loaded as a
                library along with the Wolfram Language in the CC) used to
                direct scientific activities such as conduct experiments,
                visualize data, search data, analyze data, conduct simulations,
                generate reports, etc.
              </p>
              <p>
                “<strong>Subject User</strong>” means any individual or entity
                that directly or indirectly through another User accesses or
                uses the ECL under your account. The term “Subject User” does
                not include individuals or entities when they are accessing or
                using the ECL under their own ECL account.
              </p>
              <p>
                “<strong>Suggestions</strong>” means all suggested improvements
                to the ECL that you provide to us, including any bug reports.
              </p>
              <p>
                “<strong>Team</strong>” means a collection of ECL Users who
                share access to instruments, samples, data, etc. as part of a
                working research group. ECL Users may be members of multiple
                Teams.
              </p>
              <p>
                “<strong>Term</strong>” means the term of this Agreement
                described in Section 6.1.
              </p>
              <p>
                “<strong>Third Party Content</strong>” means Content made
                available to you by any third party on the ECL or in conjunction
                with the ECL, such as papers and experimental protocols uploaded
                by another User to the ECL and shared with you.
              </p>
              <p>
                “<strong>Third Party Service Terms</strong>” means the rights
                and restrictions for particular aspects of the ECL located at{" "}
                <ExternalLink
                  href="https://www.emeraldcloudlab.com/legal"
                  rel="nofollow"
                >
                  https://www.emeraldcloudlab.com/legal
                </ExternalLink>
                , as they may be updated by us from time to time.
              </p>
              <p>
                “<strong>Thread</strong>” means allocated laboratory bandwidth
                per period of time for execution of procedures specified by
                Experiments.
              </p>
              <p>
                “<strong>User</strong>” means an individual or entity with a
                validly registered ECL account. Some Users, where permitted,
                have access rights and privileges to run experiments, consume,
                direct the use of, and dispose of samples, generate, modify, and
                delete data, analysis, reports, and simulations. A User’s
                ability to perform certain actions on the ECL may be limited in
                certain contexts, <em>e.g.</em>, a User in a Sharing Team does
                not have permission to run experiments.
              </p>
              <p>
                “<strong>Your Content</strong>” means information, data, code,
                Your Experiments, and other Content, in any form or medium, that
                is created or uploaded by you through the ECL or that
                incorporates or is derived from the Processing of such
                information, data, or Content by or through the ECL, excluding
                ECL Content as described below. Your Content may include
                information associated with Your Experiments, including but not
                limited to experimental protocols, SLL code, experimental
                results, experimental session information, and information
                regarding Your Samples. However, the scope of Your Content is
                limited to your specific use of that Content. For example, to
                the extent Your Content is coextensive with that of other ECL
                Users, <em>e.g.</em>, when a specific instrument is used to
                execute two User’s respective Experiments, the instrument
                identifier is included in the Content of both Users but Your
                Content is limited to your specific usage of that instrument,
                not the other User’s use of that instrument or that instrument
                in general. Further, to the extent Your Content includes ECL
                Content, <em>e.g.</em>, incorporates SLL functions provided by
                Emerald, Your Content only includes your specific use of the ECL
                Content, <em>e.g.</em>, your code which utilizes those SLL
                functions, not the SLL functions themselves. For avoidance of
                doubt, inclusion of ECL Content in Your Content does not grant
                you any ownership right in the ECL Content.
              </p>
              <p>
                “<strong>Your Experiments</strong>” means Experiments you or any
                Subject User (a) run on the ECL, (b) cause to run on the ECL,
                (c) specify using the ECL, or (d) upload to the ECL under your
                account or otherwise transfer, process, use, or store in
                connection with your account.
              </p>
              <p>
                “<strong>Your Samples</strong>” means any physical materials
                sent to or provisioned by Emerald for use in Your Experiments,
                other analysis, or storage on the ECL. Your Samples also
                encompasses the physical product of any Experiment run on Your
                Samples, <em>e.g.</em>, if you provide ECL with a DNA template
                and run PCR on the provided template then the resultant
                amplified DNA is also included in Your Samples.&nbsp;
              </p>
            </Section>
          </StyledCol>
        </Row>
      </LegalPageContainer>
    </Layout>
  );
};

export default LegalPage;

const ExternalLink = styled.a`
  border-bottom: 1px solid;
  letter-spacing: normal;
  transition: all 0.25s ease;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

const LegalPageContainer = styled(PageContainer)`
  margin-bottom: 3rem;
  margin-top: 5rem;
`;

const Section = styled.div`
  margin-bottom: 4.5rem;
`;

const StyledCol = styled(Col)`
  margin-bottom: 1.5rem;
`;

const SubHeading = styled.h2`
  margin-bottom: 1.8rem;
  margin-top: 1.5rem;
`;

const Tabbed = styled.p`
  margin-left: 3rem;
`;

const DoubleTab = styled.p`
  margin-left: 6rem;
`;

const Title = styled.h1`
  font-size: 2.6rem;
  line-height: 3rem;
  font-weight: 600;
  margin-bottom: 3rem;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
`;
